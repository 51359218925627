import React from "react";
import { Route, Router, Redirect } from "react-router-dom";
import MicroFront from "../MicroFront";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import store from "store";

import "./css/App.css";
import "libicons/libicons/style.css";
import "starscream-theme/dist/css/dsgov.css";

import history from "./history";
import {
  AUTH_PATH,
  AUTH_PATH_LOGOUT_OUT,
  FRONTEND_INDEX
} from "../common/constants";
import { unStoreToken } from "../common/AuthManager";

import DtpHeaderAdmin from "components/DtpHeaderAdmin/DtpHeaderAdmin";
import DtpMenuAdmin from "components/DtpMenuAdmin/DtpMenuAdmin";
import DtpAdmin from "components/DtpAdmin/DtpAdmin";
import DtpContentAdmin from "components/DtpContentAdmin/DtpContentAdmin";
import DtpBreadcrumb from "components/DtpBreadcrumb/DtpBreadcrumb";

import DtpSnackbar from "components/DtpSnackbar/DtpSnackbar";
import DtpBlockUi from "components/DtpBlockUi/DtpBlockUi";

const {
  REACT_APP_REQUERIMENTOS: requerimentosHost,
  REACT_APP_TAREFAS: tarefasHost,
  REACT_APP_CNIS: cnisHost,
  REACT_APP_MEUINSS: meuinssHost,
  REACT_APP_SIRIS: sirisHost,
  REACT_APP_GETGESTAO: getGestaoHost,
  REACT_APP_GESTAO: gestaoHost,
  REACT_APP_AVALIACAO: avaliacaoHost,
  REACT_APP_WORKFLOW: workflowHost,
  REACT_APP_PRODUTIVIDADE: produtividadeHost
} = process.env;

const Requerimentos = ({ history }) => (
  <MicroFront history={history} host={requerimentosHost} name="requerimentos" />
);

const Tarefas = ({ history }) => (
  <MicroFront history={history} host={tarefasHost} name="tarefas" />
);

const CNIS = ({ history }) => (
  <MicroFront history={history} host={cnisHost} name="CNIS" />
);

const Meuinss = ({ history }) => (
  <MicroFront history={history} host={meuinssHost} name="meuinss" />
);

const SirisGestao = ({ history }) => (
  <MicroFront history={history} host={sirisHost} name="sirisgestao" />
);

const Modelos = ({ history }) => (
  <MicroFront history={history} host={getGestaoHost} name="getgestao" />
);

const Parametros = ({ history }) => (
  <MicroFront history={history} host={gestaoHost} name="gestao" />
);

const AcoesEmLote = ({ history }) => (
  <MicroFront history={history} host={gestaoHost} name="gestao" />
);

const LimitePuxadaTarefaPesquisa = ({ history }) => (
  <MicroFront history={history} host={gestaoHost} name="gestao" />
);

const PapelServicoManualPesquisa = ({ history }) => (
  <MicroFront history={history} host={gestaoHost} name="gestao" />
);

const Assinatura = ({ history }) => (
  <MicroFront history={history} host={getGestaoHost} name="getgestao" />
);

const Avaliacao = ({ history }) => (
  <MicroFront history={history} host={avaliacaoHost} name="avaliacao" />
);

const Produtividade = ({ history }) => (
  <MicroFront history={history} host={produtividadeHost} name="produtividade" />
);

const Workflow = ({ history }) => (
  <MicroFront
    history={history}
    host={workflowHost}
    refresh={true}
    name="workflow"
  />
);

const Fragmentos = ({ history }) => (
  <MicroFront
    history={history}
    host={workflowHost}
    refresh={true}
    name="workflow"
  />
);
class App extends React.Component {
  static doLogin() {
    return window.open(AUTH_PATH, "_self");
  }

  static async doLogout() {
    await unStoreToken();
    return window.open(AUTH_PATH_LOGOUT_OUT, "_self");
  }

  static async mudarAbrangencia() {
    await unStoreToken();
    setTimeout(function () {
      window.open(FRONTEND_INDEX, "_self");
    }, 300);
  }

  constructor(props) {
    super(props);

    this.state = {
      activeMenuKey: 1
    };

    this.isMenuItemActive = this.isMenuItemActive.bind(this);
    this.isDropdownMenuItemActive = this.isDropdownMenuItemActive.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  isMenuItemActive(key) {
    const { activeMenuKey } = this.state;
    return key && activeMenuKey && activeMenuKey === key;
  }

  isDropdownMenuItemActive(key) {
    const { activeMenuKey } = this.state;
    return (
      key && activeMenuKey && activeMenuKey >= key && activeMenuKey < key + 1
    );
  }

  handleMenuItemClick(key) {
    this.setState({
      activeMenuKey: key
    });
  }

  render() {
    const { userData } = this.props;
    store.getState().homeData.history = history;
    const appInfo = {
      firstName: "Portal de Atendimento | INSS",
      lastName: "",
      owner: "11.11.2022"
    };

    return (
      <Router history={history}>
        <React.Fragment>
          <DtpBlockUi blocking={this.state.loadingPage} showSpinner={true} />
          <DtpSnackbar />
          <DtpAdmin>
            <DtpHeaderAdmin
              key="headerAdmin"
              appInfo={appInfo}
              doLogout={App.doLogout}
              mudarAbrangencia={App.mudarAbrangencia}
            ></DtpHeaderAdmin>
            <DtpMenuAdmin
              key="menuAdmin"
              version={appInfo.owner}
              userData={userData}
            />
            <Route key="breadcrumbAdmin" path="/" component={DtpBreadcrumb} />
            <DtpContentAdmin key="contentAdmin">
              <Route exact path="/" component={Tarefas} />
              <Route path="/requerimentos" component={Requerimentos} />
              <Route path="/tarefas" component={Tarefas} />
              <Route path="/avaliacao" component={Avaliacao} />
              <Route exact path="/cnis" component={CNIS} />
              <Route exact path="/meuinss" component={Meuinss} />
              <Route exact path="/sirisgestao" component={SirisGestao}/>
              <Route exact path="/modelos" component={Modelos} />
              <Route exact path="/parametros" component={Parametros} />
              <Route exact path="/acoes-em-lote" component={AcoesEmLote} />
              <Route
                exact
                path="/limite-puxada-tarefa"
                component={LimitePuxadaTarefaPesquisa}
              />
              <Route exact path="/bloqueio-fluxo-automatico" component={PapelServicoManualPesquisa} />
              <Route exact path="/assinatura" component={Assinatura} />
              <Route path="/produtividade" component={Produtividade} />
              <Route exact path="/workflow">
                <Redirect to="/workflow/cenarios" />
              </Route>
              <Route path="/workflow/cenarios" component={Workflow} />
              <Route exact path="/workflow/fragmentos" component={Fragmentos} />
            </DtpContentAdmin>
          </DtpAdmin>
        </React.Fragment>
      </Router>
    );
  }
}

App.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    document: PropTypes.shape({
      label: PropTypes.string,
      number: PropTypes.string
    }),
    information: PropTypes.string
  })
};

App.defaultProps = {
  userData: {}
};

const mapStateToProps = (state) => ({
  userData: state.homeData.userData,
  loader: state.homeData.loader,
  mensagem: state.homeData.mensagem,
  loadingPage: state.homeData.loadingPage
});

export default connect(mapStateToProps, null)(App);
