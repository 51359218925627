import React from "react";

import history from "features/history";
import { possuiPermissoes } from "common/AuthManager";

const submenus = [
  "/meuinss",
  "/cnis",
  "/parametros",
  "/acoes-em-lote",
  "/limite-puxada-tarefa",
  "/bloqueio-fluxo-automatico",
];

class DtpMenuAdmin extends React.Component {
  constructor() {
    super();
    this.state = { selectedMenu: "principal" };
    this.handleSelectMenu = this.handleSelectMenu.bind(this);
  }

  handleSelectSubMenu(submenuSelecionado) {
    this.setState({ selectedMenu: submenuSelecionado });
  }

  handleSelectMenu(e, novoMenu, novoPath, clickSubMenu) {
    if (e) {
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
    }
    const selectedMenu = this.state.selectedMenu;
    const subMenuSelecionado = novoPath && submenus.includes(novoPath);
    if (
      this.isGestao(selectedMenu) &&
      this.isGestao(novoMenu) &&
      !subMenuSelecionado &&
      clickSubMenu
    ) {
      this.setState({ selectedMenu: "" });
    } else {
      this.setState({ selectedMenu: novoMenu });
    }
    if (novoPath) {
      history.push(novoPath);
      window.location.reload();
    }
  }

  isPrincipal(selectedMenu) {
    return selectedMenu === "principal";
  }

  isGestao(selectedMenu) {
    return selectedMenu === "gestao";
  }

  isAvaliacao(selectedMenu) {
    return selectedMenu === "avaliacao";
  }

  isProdutividade(selectedMenu) {
    return selectedMenu === "produtividade";
  }

  isWorkflow(selectedMenu) {
    return selectedMenu === "workflow";
  }

  isFragmentos(selectedMenu) {
    return selectedMenu === "fragmentos";
  }

  render() {
    let { userData, isOpen, hover, version, onHover, outHover, menuAdminRef } =
      this.props;
    const selectedMenu = this.state.selectedMenu;
    const pathAtual = document.location.pathname;
    const isGestaoSelected = this.isGestao(selectedMenu);
    const isProdutividadeSelected = this.isProdutividade(selectedMenu);
    return (
      <nav
        ref={menuAdminRef}
        className={`dtp-menu-admin ${
          isOpen ? "dtp-menu-open" : "dtp-menu-close"
        }`}
        onMouseEnter={onHover}
        onMouseLeave={outHover}
      >
        <section className="dtp-painel-user">
          <div
            className={`user-card ${
              isOpen || hover ? "user-card-open" : "user-card-close"
            }`}
          >
            <i className="icon ico-user-c" aria-hidden="true"></i>
            {(isOpen || hover) && (
              <React.Fragment>
                <div className="user-name">
                  <span className="first-name">{userData.firstName}</span>&nbsp;
                </div>
                <div className="user-doc">
                  <span className="doc">
                    {userData.document.label}: {userData.document.number}
                  </span>
                </div>
                <div className="user-body">
                  <div className="login-information">
                    {userData.information}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </section>
        <section className="dtp-menu-itens">
          <ul>
            <li
              className={
                isOpen || hover
                  ? this.isPrincipal(selectedMenu)
                    ? "openli selected"
                    : "openli"
                  : this.isPrincipal(selectedMenu)
                  ? "closeli selected"
                  : "closeli"
              }
              onClick={(e) => this.handleSelectMenu(e, "principal", "/")}
            >
              <i className="fas fa-home" aria-label="principal"></i>
              {(isOpen || hover) && <span role="link">Principal</span>}
            </li>
            {possuiPermissoes("MENU_GESTAO") && (
              <React.Fragment>
                <li
                  className={
                    isOpen || hover
                      ? isGestaoSelected
                        ? "openli selected dtp-submenu"
                        : "openli dtp-submenu"
                      : isGestaoSelected
                      ? "closeli selected dtp-submenu"
                      : "closeli dtp-submenu"
                  }
                  onClick={(e) =>
                    this.handleSelectMenu(e, "gestao", null, true)
                  }
                >
                  <i className="fas fa-clipboard-list" aria-label="gestão"></i>
                  {(isOpen || hover) && (
                    <React.Fragment>
                      <span role="link">Gestão</span>
                    </React.Fragment>
                  )}
                  {isGestaoSelected && (
                    <i
                      className="fa fa-angle-down pull-right"
                      aria-label="recolher"
                    ></i>
                  )}
                  {!isGestaoSelected && (
                    <i
                      className="fa fa-angle-left pull-right"
                      aria-label="expandir"
                    ></i>
                  )}
                  {isGestaoSelected && (isOpen || hover) && (
                    <ul style={{ marginTop: "15px" }}>
                      {possuiPermissoes("MANTER_MEU_INSS_GESTAO") && (
                        <li
                          className={
                            pathAtual.startsWith("/meuinss")
                              ? "submenu-selected"
                              : "closedli"
                          }
                        >
                          <i
                            aria-label="meu inss"
                            className="fas fa-address-card"
                          ></i>
                          <span
                            role="link"
                            onClick={(e) =>
                              this.handleSelectMenu(e, "gestao", "/meuinss")
                            }
                          >
                            Meu INSS
                          </span>
                        </li>
                      )}
                      {possuiPermissoes("MANTER_SIRIS_GESTAO") && (
                        <li
                          className={
                            pathAtual.startsWith("/sirisgestao")
                              ? "submenu-selected"
                              : "closedli"
                          }
                        >
                          <i
                            aria-label="meu inss"
                            className="fas fa-address-card"
                          ></i>
                          <span
                            role="link"
                            onClick={(e) =>
                              this.handleSelectMenu(e, "gestao", "/sirisgestao")
                            }
                          >
                            Siris
                          </span>
                        </li>
                      )}
                      {possuiPermissoes("INCLUIR_MODELO_DESPACHO") && (
                        <li
                          className={
                            pathAtual.startsWith("/modelos")
                              ? "submenu-selected"
                              : "closedli"
                          }
                        >
                          <i
                            aria-label="modelos"
                            className="fas fa-address-card"
                          ></i>
                          <span
                            role="link"
                            onClick={(e) =>
                              this.handleSelectMenu(e, "gestao", "/modelos")
                            }
                          >
                            Modelos
                          </span>
                        </li>
                      )}
                      {possuiPermissoes("ALTERAR_PARAMETROS_SISTEMA") && (
                        <li
                          className={
                            pathAtual.startsWith("/parametros")
                              ? "submenu-selected"
                              : "closedli"
                          }
                        >
                          <i
                            aria-label="parâmetros"
                            className="fas fa-tasks"
                          ></i>
                          <span
                            role="link"
                            onClick={(e) =>
                              this.handleSelectMenu(e, "gestao", "/parametros")
                            }
                          >
                            Parâmetros
                          </span>
                        </li>
                      )}
                      {possuiPermissoes("CONSULTAR_ACOES_LOTE") && (
                        <li
                          className={
                            pathAtual.startsWith("/acoes-em-lote")
                              ? "submenu-selected"
                              : "closedli"
                          }
                        >
                          <i
                            aria-label="acoesemlote"
                            className="fas fa-tasks"
                          ></i>
                          <span
                            role="link"
                            onClick={(e) =>
                              this.handleSelectMenu(
                                e,
                                "gestao",
                                "/acoes-em-lote"
                              )
                            }
                          >
                            Ações em Lote
                          </span>
                        </li>
                      )}
                      {possuiPermissoes("MANTER_LIMITE_TAREFA") && (
                        <li
                          className={
                            pathAtual.startsWith("/limite-puxada-tarefa")
                              ? "submenu-selected"
                              : "closedli"
                          }
                        >
                          <i
                            aria-label="limitepuxadatarefa"
                            className="fas fa-tasks"
                          ></i>
                          <span
                            role="link"
                            onClick={(e) =>
                              this.handleSelectMenu(
                                e,
                                "gestao",
                                "/limite-puxada-tarefa"
                              )
                            }
                          >
                            Limite Puxada Tarefa
                          </span>
                        </li>
                      )}
                      {possuiPermissoes("LISTAR_PAPEIS_SERVICO_MANUAL") && (
                        <li
                          className={
                            pathAtual.startsWith("/bloqueio-fluxo-automatico")
                              ? "submenu-selected"
                              : "closedli"
                          }
                        >
                          <i
                            aria-label="bloqueiofluxoautomatico"
                            className="fas fa-tasks"
                          ></i>
                          <span
                            role="link"
                            onClick={(e) =>
                              this.handleSelectMenu(
                                e,
                                "gestao",
                                "/bloqueio-fluxo-automatico"
                              )
                            }
                          >
                            Bloqueio Fluxo Automático
                          </span>
                        </li>
                      )}
                      <li
                        className={
                          pathAtual.startsWith("/assinatura")
                            ? "submenu-selected"
                            : "closedli"
                        }
                      >
                        <i
                          aria-label="assinatura"
                          className="fas fa-file-signature"
                        ></i>
                        <span
                          role="link"
                          onClick={(e) =>
                            this.handleSelectMenu(e, "gestao", "/assinatura")
                          }
                        >
                          Assinatura
                        </span>
                      </li>
                    </ul>
                  )}
                </li>
              </React.Fragment>
            )}
            {possuiPermissoes("MENU_AVALIACAO_SOCIAL") && (
              <li
                className={
                  isOpen || hover
                    ? this.isAvaliacao(selectedMenu)
                      ? "openli selected"
                      : "openli"
                    : this.isAvaliacao(selectedMenu)
                    ? "closeli selected"
                    : "closeli"
                }
                onClick={(e) =>
                  this.handleSelectMenu(e, "avaliacao", "/avaliacao")
                }
              >
                <i className="fas fa-file-alt"></i>
                {(isOpen || hover) && <span role="link">Avaliação Social</span>}
              </li>
            )}
            {(possuiPermissoes("CONSULTAR_LINHA_TRABALHO") ||
              possuiPermissoes("CONSULTAR_DASHBOARD_PROFISSIONAL")) && (
              <li
                className={
                  isOpen || hover
                    ? isProdutividadeSelected
                      ? "openli selected"
                      : "openli"
                    : isProdutividadeSelected
                    ? "closeli selected"
                    : "closeli"
                }
                onClick={(e) =>
                  this.handleSelectMenu(e, "produtividade", "/produtividade")
                }
              >
                <i className="fas fa-tachometer-alt"></i>
                {(isOpen || hover) && <span role="link">Produtividade</span>}
              </li>
            )}
            {possuiPermissoes("MENU_WORKFLOW") &&
              possuiPermissoes("MANTER_CENARIOS") && (
                <li
                  className={
                    isOpen || hover
                      ? pathAtual.startsWith("/workflow/cenario")
                        ? "openli selected"
                        : "openli"
                      : this.isWorkflow(selectedMenu)
                      ? "closeli selected"
                      : "closeli"
                  }
                  onClick={(e) =>
                    this.handleSelectMenu(e, "workflow", "/workflow/cenarios")
                  }
                >
                  <i className="fas fa-sitemap"></i>
                  {(isOpen || hover) && <span role="link">Workflow</span>}
                </li>
              )}
            {possuiPermissoes("MENU_WORKFLOW") &&
              possuiPermissoes("MANTER_FRAGMENTOS") && (
                <li
                  className={
                    isOpen || hover
                      ? pathAtual.startsWith("/workflow/fragmento")
                        ? "openli selected"
                        : "openli"
                      : this.isWorkflow(selectedMenu)
                      ? "closeli selected"
                      : "closeli"
                  }
                  onClick={(e) =>
                    this.handleSelectMenu(
                      e,
                      "fragmentos",
                      "/workflow/fragmentos"
                    )
                  }
                >
                  <i className="fas fa-puzzle-piece"></i>
                  {(isOpen || hover) && <span role="link">Fragmentos</span>}
                </li>
              )}
          </ul>
        </section>
        <section className="dtp-menu-configuracao">
          <ul>
            <li
              className={
                isOpen || hover
                  ? pathAtual.startsWith("/configuracoes")
                    ? "openli selected"
                    : "openli"
                  : pathAtual === "/configuracoes"
                  ? "closeli selected"
                  : "closeli"
              }
              onClick={() => history.push("/configuracoes")}
            >
              <i className="fas fa-cog" aria-label="configurações"></i>
              {(isOpen || hover) && <span role="link">Configurações</span>}
            </li>
          </ul>
        </section>
        <footer>{version}</footer>
      </nav>
    );
  }
}

export default DtpMenuAdmin;
